import React, { CSSProperties, ReactNode } from "react";
import classNames from "classnames";
import "./index.scss";

interface IProps {
  id?: string;
  className?: string;
  styles?: CSSProperties;
  children: ReactNode;
  sectionCustom?: string;
}

export const Section = ({
  id,
  className,
  styles,
  children,
  sectionCustom = "",
}: IProps) => {
  return (
    <section
      id={id}
      className={classNames(className, `section-common${sectionCustom}`)}
      style={styles}
    >
      {children}
    </section>
  );
};
