import React from "react";
import { Section, Typography } from "components";
import "./index.scss";

import FeatureScreen from "../../../../assets/images/features/features_2.png";
import SetupIcon from "../../../../assets/images/icons/easier-section/settings.png";
import CardIcon from "../../../../assets/images/icons/easier-section/creditcard.png";
import NotificationsIcon from "../../../../assets/images/icons/easier-section/notification.png";
import InsightsIcon from "../../../../assets/images/icons/easier-section/connection.png";
import OrderingIcon from "../../../../assets/images/icons/easier-section/touch.png";
import AppIcon from "../../../../assets/images/icons/easier-section/smartphone.png";

export const Features = () => {
  return (
    <Section>
      <div className="feature-screen">
        <img src={FeatureScreen} alt="" />
      </div>

      <div className="container features-container">
        <div className="features-wrapper">
          <div className="features-item">
            <img src={SetupIcon} alt="" />
            <Typography variant="h3">Quick Setup</Typography>
            <Typography variant="baseRegular" className="storefront-sub-title">
              Easy onboarding process to set up your online restaurant
              instantly.
            </Typography>
          </div>

          <div className="features-item">
            <img src={CardIcon} alt="" />
            <Typography variant="h3">Accept Online Payments</Typography>
            <Typography variant="baseRegular" className="storefront-sub-title">
              Multiple payment options will ensure customer satisfaction and
              your payments are guaranteed.
            </Typography>
          </div>

          <div className="features-item">
            <img src={NotificationsIcon} alt="" />
            <Typography variant="h3">SMS/Email Notifications</Typography>
            <Typography variant="baseRegular" className="storefront-sub-title">
              Notify your customers at every step of the food preparation and
              delivery process.
            </Typography>
          </div>

          <div className="features-item">
            <img src={InsightsIcon} alt="" />
            <Typography variant="h3">Customer Insights</Typography>
            <Typography variant="baseRegular" className="storefront-sub-title">
              Analytics features will guide you to scale your business in a
              sustainable way.
            </Typography>
          </div>

          <div className="features-item">
            <img src={OrderingIcon} alt="" />
            <Typography variant="h3">Mobile Ordering for Diners</Typography>
            <Typography variant="baseRegular" className="storefront-sub-title">
              So you can focus on customer satisfaction while we take care of
              the ordering process.
            </Typography>
          </div>

          <div className="features-item">
            <img src={AppIcon} alt="" />
            <Typography variant="h3">Order Manager Mobile App</Typography>
            <Typography variant="baseRegular" className="storefront-sub-title">
              Download the Order Manager app on your mobile
            </Typography>
          </div>
        </div>
      </div>
    </Section>
  );
};
