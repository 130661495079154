import { Section, Typography } from "components";
import "./index.scss";

import StoreFrontIcon from "../../../../assets/images/icons/store.png";
import DashboardtIcon from "../../../../assets/images/icons/confirm.png";

import CustomizableIcon from "../../../../assets/images/icons/easier-section/website.png";
import OrdersIcon from "../../../../assets/images/icons/easier-section/address.png";
import MenuIcon from "../../../../assets/images/icons/easier-section/menu.png";
import ManageIcon from "../../../../assets/images/icons/easier-section/cooking.png";
import PromoIcon from "../../../../assets/images/icons/easier-section/discount.png";
import AnaliticsIcon from "../../../../assets/images/icons/easier-section/chart.png";

export const EasyToUse = () => {
  return (
    <div className="easy-to-use-wrapper">
      <Section>
        <div className="container">
          <Typography variant="h1" className="main-heading">
            Easier than ever
          </Typography>
          <Typography variant="h2" className="main-sub-heading">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Voluptatibus numquam eius molestias animi voluptatum enim illo ut
            ptatibus numquam eius molestias animi voluptatum enim.
          </Typography>

          <div className="easy-connection">
            <div className="easy-product-item--store-front">
              <img src={StoreFrontIcon} alt="" />
            </div>
            <div className="easy-product-item--dashboard">
              <img src={DashboardtIcon} alt="" />
            </div>
          </div>

          <div className="easy-sec-contents">
            <div className="easy-sec-storefront">
              <div className="easy-sec-header">
                <Typography variant="h3">Storefront</Typography>
                <Typography
                  variant="baseRegular"
                  className="storefront-sub-title"
                >
                  Get started with your own website.
                </Typography>
              </div>

              <div className="easy-sec-content-wrapper">
                <div className="easy-sec-content-item">
                  <div className="es-icon">
                    <img src={CustomizableIcon} alt="" />
                  </div>
                  <div className="es-details">
                    <Typography variant="h4">
                      Fully-Customizable <span>Website</span>
                    </Typography>
                    <Typography variant="baseRegular">
                      Mobile responsive website customized with your brand
                      colors, images and text content.
                    </Typography>
                  </div>
                </div>

                <div className="easy-sec-content-item">
                  <div className="es-icon">
                    <img src={OrdersIcon} alt="" />
                  </div>
                  <div className="es-details">
                    <Typography variant="h4">
                      Delivery, Pickup or Dine-in Orders
                    </Typography>
                    <Typography variant="baseRegular">
                      Maintain a single interface for all customers and make
                      their life easier.
                    </Typography>
                  </div>
                </div>

                <div className="easy-sec-content-item">
                  <div className="es-icon">
                    <img src={MenuIcon} alt="" />
                  </div>
                  <div className="es-details">
                    <Typography variant="h4">
                      Well-Organized Photo Menu
                    </Typography>
                    <Typography variant="baseRegular">
                      Fully detailed menus for all your customers, including
                      pre-orders and dine-in with portion size and add-ons.
                    </Typography>
                  </div>
                </div>
              </div>
            </div>

            <div className="easy-sec-dashboard">
              <div className="easy-sec-header">
                <Typography variant="h3">Dashboard</Typography>
                <Typography
                  variant="baseRegular"
                  className="storefront-sub-title"
                >
                  Manage everything on order management dashboard.
                </Typography>
              </div>

              <div className="easy-sec-content-wrapper">
                <div className="easy-sec-content-item">
                  <div className="es-icon">
                    <img src={ManageIcon} alt="" />
                  </div>
                  <div className="es-details">
                    <Typography variant="h4">Easy Order Management</Typography>
                    <Typography variant="baseRegular">
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Dolores animi eveniet.
                    </Typography>
                  </div>
                </div>

                <div className="easy-sec-content-item">
                  <div className="es-icon">
                    <img src={PromoIcon} alt="" />
                  </div>
                  <div className="es-details">
                    <Typography variant="h4">Manage Promotions</Typography>
                    <Typography variant="baseRegular">
                      Offer promo codes and happy hours to attract more
                      customers and cross-sell your food.
                    </Typography>
                  </div>
                </div>

                <div className="easy-sec-content-item">
                  <div className="es-icon">
                    <img src={AnaliticsIcon} alt="" />
                  </div>
                  <div className="es-details">
                    <Typography variant="h4">Advanced Analytics</Typography>
                    <Typography variant="baseRegular">
                      Increase your sales by providing what your cutomers really
                      want. Experience the power of your own data.
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
};
