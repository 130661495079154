import "./index.scss";

interface TextareaProps {
  label?: string | number;
  id?: string;
  name: string;
  value?: string;
  onChange: (e: any) => void;
  isError?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  cols?: number;
  placeholder?: string;
  maxLength?: number;
  showCount?: boolean;
}

export const Textarea = ({
  id,
  name,
  value,
  label,
  onChange,
  isError,
  disabled,
  errorMessage,
  cols,
  placeholder,
  maxLength,
  showCount,
}: TextareaProps) => {
  return (
    <div className="textarea-container">
      <label htmlFor={id}>{label}</label>
      <textarea
        className={`h-textarea ${isError && "input-error"}`}
        cols={cols}
        value={value}
        name={name || id}
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={onChange}
        disabled={disabled}
      />
      {showCount && maxLength && (
        <span className="textarea-char-count">
          {value ? value.length : 0}/{maxLength} Characters
        </span>
      )}
      {isError && <span className="input-error-msg">{errorMessage}</span>}
    </div>
  );
};

Textarea.defaultProps = {
  label: "",
  id: undefined,
  value: undefined,
  isError: false,
  errorMessage: "",
  maxLength: undefined,
};
