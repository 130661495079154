import React from "react";
import { Section, Typography } from "components";
import "./index.scss";

import StepOne from "../../../../assets/images/steps/step-01.png";
import StepTwo from "../../../../assets/images/steps/step-02.png";
import StepThree from "../../../../assets/images/steps/step-03.png";

export const GetReady = () => {
  return (
    <div className="get-ready-wrapper">
      <Section>
        <div className="container">
          <Typography variant="h1" className="main-heading">
            Build Your Online Restaurant
          </Typography>
          <Typography variant="h2" className="main-sub-heading">
            in 3 easy steps.
          </Typography>

          <div className="get-ready-steps-wrapper">
            <div className="get-ready-step">
              <div className="get-ready-image">
                <img src={StepOne} alt="" />
              </div>
              <div className="get-ready-content">
                <Typography variant="h3">Create & Customize</Typography>
                <Typography
                  variant="baseRegular"
                  className="get-ready-sub-title"
                >
                  Choose your own URL, add basic info and register your
                  restaurant.
                </Typography>
              </div>
            </div>

            <div className="get-ready-step">
              <div className="get-ready-image">
                <img src={StepTwo} alt="" />
              </div>
              <div className="get-ready-content">
                <Typography variant="h3">Add Photo Menu</Typography>
                <Typography
                  variant="baseRegular"
                  className="get-ready-sub-title"
                >
                  Add photos and create an attractive menu for all your
                  customers.
                </Typography>
              </div>
            </div>

            <div className="get-ready-step">
              <div className="get-ready-image">
                <img src={StepThree} alt="" />
              </div>
              <div className="get-ready-content">
                <Typography variant="h3">Get Orders Online</Typography>
                <Typography
                  variant="baseRegular"
                  className="get-ready-sub-title"
                >
                  Online restaurant that works 24x7 with every feature it needs
                  to increase sales.
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
};
