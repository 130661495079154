import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { HiMenuAlt3, HiOutlineXCircle } from "react-icons/hi";
import Logo from "../../../assets/images/logo.png";
import "./index.scss";

const activeStyle = {
  borderBottom: "3px solid rgb(112 32 253)",
  fontWeight: 500,
};

export const NavBar = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);

  return (
    <div className="container">
      <nav className="navbar" onClick={(e) => e.stopPropagation()}>
        <div className="nav-container">
          <div className="nav-logo">
            <NavLink to="/" className="hytch-logo">
              <img src={Logo} alt="hytch logo" />
            </NavLink>
          </div>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                to="/register"
                // style={({ isActive }) => (isActive ? activeStyle : {})}
                className="nav-links nav-sign-up"
                onClick={click ? handleClick : () => {}}
              >
                Signup
              </NavLink>
            </li>
          </ul>

          <div className="nav-icon" onClick={handleClick}>
            {click ? <HiOutlineXCircle /> : <HiMenuAlt3 />}
          </div>
        </div>
      </nav>
      <div className={click ? "main-container" : ""} onClick={() => Close()} />
    </div>
  );
};
