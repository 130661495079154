import React from "react";
import { Section, Typography, Button } from "components";
import "./index.scss";

import MainImage from "../../../../assets/images/d_features/Menu_page.png";
import DashboardImage from "../../../../assets/images/d_features/Order-Board.png";

export const DiscoverFeatures = () => {
  return (
    <div className="discover-features-wrapper">
      <Section>
        <div className="container">
          <div className="discover-features-main-wrapper">
            <div className="content-sidebar">
              <Typography variant="h1" className="df-main-heading">
                The
                <em> Storefront </em>
                with full of fearures
              </Typography>

              <Typography variant="h2" className="main-sub-heading">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Assumenda sed vitae porro nemo, vel nesciunt sunt molestiae
                architecto accusantium at explicabo, maxime consequatur,
                praesentium dignissimos reprehenderit veritatis blanditiis
                quisquam alias!
              </Typography>

              <div className="df-info-content">
                <div className="df-info-item">
                  <Typography variant="h5">
                    Can I add any site to the sidebar?
                  </Typography>
                  <Typography variant="baseRegular">
                    Yes, you can add any page or site of your choice to the
                    sidebar. Select + on your sidebar to add.
                  </Typography>
                </div>

                <div className="df-info-item">
                  <Typography variant="h5">
                    Do the sidebar apps open within the same tab?
                  </Typography>
                  <Typography variant="baseRegular">
                    Yes, sidebar apps open within a side pane in the same tab
                    you are on so that you can multitask with it on the side
                    without breaking...
                  </Typography>
                </div>
              </div>

              <Button text="Try Now" shape="square" />
            </div>
            <div className="content-image">
              <img src={MainImage} alt="" />
            </div>
          </div>

          <div className="discover-features-main-wrapper df-dashboard">
            <div className="content-sidebar">
              <Typography variant="h1" className="df-main-heading">
                <em>Dashboard </em>
                with full of fearures to manage orders
              </Typography>

              <Typography
                variant="extraSmallRegular"
                className="feature-request"
              >
                * Can request features and functionality according to your
                desire.
              </Typography>
            </div>
            <div className="content-image">
              <img src={DashboardImage} alt="" />
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
};
