import React from "react";
import { Section, Typography } from "components";
import { RiComputerFill } from "react-icons/ri";
import {
  MdDeliveryDining,
  MdNotificationsActive,
  MdPayment,
} from "react-icons/md";

import "./index.scss";

import IntegrationsImage from "../../../../assets/images/features/integrations.png";

export const Integrations = () => {
  return (
    <div className="integrations-wrapper">
      <Section>
        <div className="container">
          <Typography variant="h1" className="main-heading">
            3rd Party Integrations
          </Typography>
          <Typography variant="h2" className="main-sub-heading">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          </Typography>

          <div className="integrations-inner">
            <div className="integrations-image">
              <img src={IntegrationsImage} alt="" />
            </div>
            <div className="integrations-details">
              <Typography variant="h2" className="integrations-about">
                We support you to integrate your web services that you going to
                need with our system
              </Typography>
              <Typography variant="baseRegular" className="integrations-desc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Voluptatum error voluptatem neque, sequi dolorum atque molestiae
                totam quidem adipisci, nesciunt culpa aliquid, consequuntur
                nostrum praesentium porro ratione hic beatae minus.
              </Typography>

              <div className="integrated-items">
                <div className="integrated-item">
                  <div className="integrated-item-icon">
                    <RiComputerFill />
                  </div>
                  <div className="integrated-item-details">
                    <Typography variant="h5">
                      Custom POS Integrations
                    </Typography>
                  </div>
                </div>

                <div className="integrated-item">
                  <div className="integrated-item-icon">
                    <MdDeliveryDining />
                  </div>
                  <div className="integrated-item-details">
                    <Typography variant="h5">Delivery Services</Typography>
                  </div>
                </div>

                <div className="integrated-item">
                  <div className="integrated-item-icon">
                    <MdNotificationsActive />
                  </div>
                  <div className="integrated-item-details">
                    <Typography variant="h5">
                      SMS & Email Gateway Integration
                    </Typography>
                  </div>
                </div>

                <div className="integrated-item">
                  <div className="integrated-item-icon">
                    <MdPayment />
                  </div>
                  <div className="integrated-item-details">
                    <Typography variant="h5">
                      Custom Payment Gateway Integration
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
};
