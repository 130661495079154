/* eslint-disable */
import { useEffect, useState } from "react";
import { Button, Section, Typography } from "components";
import TextTransition from "react-text-transition";
import "./index.scss";

import HeroImage from "../../../../assets/images/hero-image.png";

const TEXTS = ["Bar", "Pub", "Restaurant", "Café"];

export const Hero = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((prevState) => prevState + 1),
      4000,
    );

    return () => clearTimeout(intervalId);
  }, []);

  return (
    <Section>
      <div className="container">
        <div className="hero-wrapper">
          <div className="hero-details">
            <Typography variant="h1" className="main-heading">
              The easiest way to offer and manage{" "}
              <span>
                {`${TEXTS[index % TEXTS.length]}`.split("").map((n, i) => (
                  <TextTransition
                    key={i}
                    children={n}
                    className="big"
                    direction="down"
                    inline
                  />
                ))}
              </span>
            </Typography>

            <Typography variant="h2" className="main-sub-heading">
              Customizable, scalable, automate & hosted software to manage and
              increase your sales.
            </Typography>

            <Button text="Get Started" shape="square" />
            <Button text="Talk to Us" shape="square" buttonType="text" />
          </div>
          <div className="hero-image">
            <img src={HeroImage} alt="" />
          </div>
        </div>
      </div>
    </Section>
  );
};
