import { Section, Typography } from "components";
import "./index.scss";

import TimeIcon from "../../../../assets/images/icons/stopwatch.png";
import UpdatedIcon from "../../../../assets/images/icons/message.png";
import ProfitabilityIcon from "../../../../assets/images/icons/percentage.png";
import FeaturesIcon from "../../../../assets/images/icons/conection.png";

export const About = () => {
  return (
    <div className="about-wrapper">
      <Section sectionCustom="--double">
        <div className="container">
          <Typography variant="h1" className="main-heading">
            Spritzer is a Powerful Restaurant Management System where You Can Do
            Everything in One Place
          </Typography>
          <Typography variant="h2" className="main-sub-heading">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Voluptatibus numquam eius molestias animi voluptatum enim illo ut
            ptatibus numquam eius molestias animi voluptatum enim.
          </Typography>

          <div className="values-wrapper">
            <div className="value-item">
              <div className="value-icon">
                <img src={TimeIcon} alt="" />
              </div>
              <Typography variant="h4">Save time on manage</Typography>
              <Typography variant="baseRegular">
                Lorem ipsum dolor sit amet, consectetur
              </Typography>
            </div>
            <div className="value-item">
              <div className="value-icon">
                <img src={UpdatedIcon} alt="" />
              </div>
              <Typography variant="h4">Keep customers updated</Typography>
              <Typography variant="baseRegular">
                Lorem ipsum dolor sit amet, consectetur
              </Typography>
            </div>
            <div className="value-item">
              <div className="value-icon">
                <img src={ProfitabilityIcon} alt="" />
              </div>
              <Typography variant="h4">Increase profitability</Typography>
              <Typography variant="baseRegular">
                Lorem ipsum dolor sit amet, consectetur
              </Typography>
            </div>
            <div className="value-item">
              <div className="value-icon">
                <img src={FeaturesIcon} alt="" />
              </div>
              <Typography variant="h4">Features for everything</Typography>
              <Typography variant="baseRegular">
                Lorem ipsum dolor sit amet, consectetur
              </Typography>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
};
