import React from "react";
import {
  NavBar,
  Hero,
  About,
  EasyToUse,
  Features,
  Integrations,
  GetReady,
  DiscoverFeatures,
  Testimonials,
} from "components";

export const Home = () => {
  return (
    <div className="home-page-container">
      <NavBar />
      <Hero />
      <About />
      <EasyToUse />
      <Features />
      <Integrations />
      <GetReady />
      <DiscoverFeatures />
      <Testimonials />
    </div>
  );
};
