import { Section, Typography } from "components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.scss";

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  speed: 1300,
  autoplaySpeed: 5000,
  cssEase: "linear",
  className: "center",
  centerMode: true,
  arrows: true,
  centerPadding: "0px",
};

export const Testimonials = () => {
  return (
    <div className="testimonials-wrapper">
      <Section>
        <div className="container">
          <Typography variant="h1" className="main-heading">
            Testimonials
          </Typography>
          <Typography variant="h2" className="main-sub-heading">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          </Typography>

          <div className="testimonials-slider">
            <Slider {...settings}>
              <div className="testimonials-item">
                <div className="testimonials-item-inner">
                  <div className="testimonial-msg">
                    <Typography variant="baseRegular">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Velit, commodi? Soluta neque praesentium debitis porro
                      laudantium aspernatur consequuntur, facere rem ad dicta,
                      eveniet cumque ipsam deserunt accusamus quibusdam
                      distinctio ut?
                    </Typography>
                  </div>
                  <div className="testimonial-person">
                    <div className="t-person-img">
                      <img
                        src="https://media.istockphoto.com/photos/smiling-man-with-arms-crossed-wearing-eyeglasses-picture-id1284027512?k=20&m=1284027512&s=170667a&w=0&h=77lzYUNMkJ-dTOPHrobY3fLPoMwBEPcwxLoWGJ7B59Q="
                        alt=""
                      />
                    </div>
                    <div className="t-person-details">
                      <Typography variant="baseMedium">Adrien Jacob</Typography>
                      <Typography variant="extraSmallRegular">
                        CEO, ABS Compeny
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>

              <div className="testimonials-item">
                <div className="testimonials-item-inner">
                  <div className="testimonial-msg">
                    <Typography variant="baseRegular">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Velit, commodi? Soluta neque praesentium debitis porro
                      laudantium aspernatur consequuntur, facere rem ad dicta,
                      eveniet cumque ipsam deserunt accusamus quibusdam
                      distinctio ut?
                    </Typography>
                  </div>
                  <div className="testimonial-person">
                    <div className="t-person-img">
                      <img
                        src="https://media.istockphoto.com/photos/smiling-man-with-arms-crossed-wearing-eyeglasses-picture-id1284027512?k=20&m=1284027512&s=170667a&w=0&h=77lzYUNMkJ-dTOPHrobY3fLPoMwBEPcwxLoWGJ7B59Q="
                        alt=""
                      />
                    </div>
                    <div className="t-person-details">
                      <Typography variant="baseMedium">Adrien Jacob</Typography>
                      <Typography variant="extraSmallRegular">
                        CEO, ABS Compeny
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>

              <div className="testimonials-item">
                <div className="testimonials-item-inner">
                  <div className="testimonial-msg">
                    <Typography variant="baseRegular">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Velit, commodi? Soluta neque praesentium debitis porro
                      laudantium aspernatur consequuntur, facere rem ad dicta,
                      eveniet cumque ipsam deserunt accusamus quibusdam
                      distinctio ut?
                    </Typography>
                  </div>
                  <div className="testimonial-person">
                    <div className="t-person-img">
                      <img
                        src="https://media.istockphoto.com/photos/smiling-man-with-arms-crossed-wearing-eyeglasses-picture-id1284027512?k=20&m=1284027512&s=170667a&w=0&h=77lzYUNMkJ-dTOPHrobY3fLPoMwBEPcwxLoWGJ7B59Q="
                        alt=""
                      />
                    </div>
                    <div className="t-person-details">
                      <Typography variant="baseMedium">Adrien Jacob</Typography>
                      <Typography variant="extraSmallRegular">
                        CEO, ABS Compeny
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>

              <div className="testimonials-item">
                <div className="testimonials-item-inner">
                  <div className="testimonial-msg">
                    <Typography variant="baseRegular">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Velit, commodi? Soluta neque praesentium debitis porro
                      laudantium aspernatur consequuntur, facere rem ad dicta,
                      eveniet cumque ipsam deserunt accusamus quibusdam
                      distinctio ut?
                    </Typography>
                  </div>
                  <div className="testimonial-person">
                    <div className="t-person-img">
                      <img
                        src="https://media.istockphoto.com/photos/smiling-man-with-arms-crossed-wearing-eyeglasses-picture-id1284027512?k=20&m=1284027512&s=170667a&w=0&h=77lzYUNMkJ-dTOPHrobY3fLPoMwBEPcwxLoWGJ7B59Q="
                        alt=""
                      />
                    </div>
                    <div className="t-person-details">
                      <Typography variant="baseMedium">Adrien Jacob</Typography>
                      <Typography variant="extraSmallRegular">
                        CEO, ABS Compeny
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </Section>
    </div>
  );
};
